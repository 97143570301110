import React from 'react'
import {
  Grid,
  Link,
  Paper,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Container,
  Button,
} from '@material-ui/core'
import SEO from '../components/seo'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import Links from '../images/utils.jpg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
const useStyles = makeStyles(theme => ({
  marginTop84: {
    marginTop: -120,
    marginBottom: 84,
  },
  none: {
    textDecoration: 'none',
  },
  menu: {
    float: 'right',
  },
  li: {
    display: 'inline-block',
  },
  link: {
    display: 'inline-block',
    fontSize: 15,
    margin: 8,
  },
}))

const OtherUtils = () => {
  const classes = useStyles()
  return (
    <div>
      <Layout>
        <SEO title="Other Utilities" />
        <NewHeader title="Other Utilities" image={Links} />
        <Container className={classes.marginTop84}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item md={8} xs={12}>
              <Paper square style={{ marginTop: 24 }}>
                <div style={{ backgroundColor: '#F00', padding: 4 }}></div>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      I.T Guest Houses
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <Link href="#">Click to Download</Link>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      Downloadable Logo
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <Button color="primary">
                        Indian Revenue Service Logo (jpg)
                      </Button>
                      <br />
                      <Button color="primary">
                        Indian Revenue Service Logo (eps)
                      </Button>
                      <br />
                      <Button color="primary">
                        Indian Revenue Service Logo (ai)
                      </Button>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      Laptop repair escalation matrix
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography variant="h6">
                      Repair escalation matrix for h.p. laptops
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </div>
  )
}

export default OtherUtils
